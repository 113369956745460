#home-container {
    margin: 0 auto;

    .section-title {
        text-align: center;
        color: #E64848;
        margin: 0;
        font-size: 2.2rem;
        font-family: Georgia, 'Times New Roman', Times, serif;
        letter-spacing: 2px;
        transition: all ease 1s;
        padding-top: 5rem;
    }
    .section-sub {
        height: 100%;
        width: 70%;
        background-color: rgba(255, 255, 255, 0.8);    
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }

    #hinh-anh {
        flex-grow: 1;
        width: 100%;
        background-color: #FF8FB1;
    }
    #section-choose-us {
        height: 100vh;
        width: 100%;
        background-color: white;
        padding-bottom: 5rem;

        .choose-us-container {
            margin-top: 3rem;
            width: 100%;
            height: 50%;
            display: flex;
            padding: 0 3rem;
        }
        .choose-us-photos {
            width: 40%;
            overflow: hidden;

            .choose-us-photo {
                height: 100%;
                margin-top: -3rem;
                margin-left: -10rem;
                opacity: .7;
            }
        }
        .choose-us-icon {
            font-size: 1.5rem;
            margin-right: 1rem;
        }
        .chooose-us-description {
            width: 60%;
            padding-left: 2rem;
        }
        .choose-us-content {
            font-size: 1.3rem;
            line-height: 2rem;
            align-items: center;
            font-family: "Poppins", Sans-serif;
            color: #726f6f;
            margin-bottom: 2rem;
        }
        .choose-us-h1 {
            color: #413F42;
            text-transform: uppercase;
            letter-spacing: 1px;
            text-align: center;
            margin: 0 auto 2rem;
        }
        .choose-us-feature {
            display: flex;
            flex-wrap: wrap;
            padding: 2rem 0 4rem;
            justify-content: space-around;

            h1 {
                margin: 1rem 0;
                font-size: 2.5rem;
                text-align: center;
            }
            h4 {
                margin-top: .5rem;
                font-size: 1.5rem;
                text-align: center;
            }
            .feature-card {
                width: 30%;
                box-shadow: 5px 5px 10px black;
                border-radius: 5px;
                &.card-1 {
                    background-color: #3AB4F2;
                    color: white;
                }
                &.card-2 {
                    background-color: #FF8FB1;
                    color: white;
                }
                &.card-3 {
                    background-color: #FFE898;
                    color: white;
                }
            }
        }
    }
    @media screen and (max-width: 1575px) {
        #section-choose-us {
            .section-sub {
                width: 85%;
            }
        }
    } // ok
    @media screen and (max-width: 1315px) {
        #section-choose-us {
            .section-sub {
                width: 90%;
            }
            .choose-us-content {
                font-size: 1.1rem;
            }
            .feature-card {
                h1 {
                    font-size: 1.5rem !important;
                }
                h4 {
                    font-size: 1.3rem;
                }
            }
        }
    } // ok
    @media screen and (max-width: 1050px) {
        #section-choose-us {
            .choose-us-container {
                padding: 0 1rem;
                position: relative;
                height: 100%;
            }
           .choose-us-description {
                width: 70%;
                z-index: 1;
                padding: 0 3rem;
                
                .choose-us-content {
                    background-color: transparent;
                    font-size: 1.6rem;
                    margin-bottom: 3rem;
                    line-height: 2.5rem;
                }
           }
           .choose-us-h1 {
            font-size: 2rem;
           }
           .choose-us-photos {
                position: absolute;
                top: 0;
                right: 0;
                width: 60%;
                height: 100%;
                z-index: 0;
                opacity: .5;
                .choose-us-photo {
                    margin-left: -10rem;
                }
           }
        }
    }
    @media screen and (max-width: 834px) {
        #section-choose-us {
           .choose-us-description {
                width: 100%;                
                .choose-us-content {
                    background-color: transparent;
                    font-size: 1.7rem;
                    margin-bottom: 3rem;
                    line-height: 3rem;
                }
           }
           .choose-us-h1 {
            font-size: 1.8rem;
           }
        }
    }
    @media screen and (max-width: 768px) {
        #section-choose-us {
            height: 90vh;
            .choose-us-container {
                height: 100%;
            }
           .choose-us-description {
                .choose-us-content {
                    font-size: 2rem;
                    line-height: 2.6rem;
                }
           }
           .choose-us-photos {
                width: 80%;
                height: 100%;
           }
        }
    }
    @media screen and (max-width: 650px) {
        #section-choose-us {
            min-height: 100vh;
            padding-bottom: 3rem;
            height: max-content;
            .choose-us-container {
                padding-bottom: 3rem;
            }
           .choose-us-description {
                .choose-us-content {
                    font-size: 1.5rem;
                    line-height: 2.5rem;
                }
           }
           .choose-us-photos {
                width: 80%;
                height: 100%;
           }
           .choose-us-h1 {
                font-size: 1.5rem;
           }
           .choose-us-feature {
                flex-direction: column;
                width: 100%;
                align-items: center;
                .feature-card {
                    width: 100%;
                    margin-bottom: 2rem;
                    h1 {
                        font-size: 2rem !important;
                    }
                    h4 {
                        font-size: 1.8rem;
                    }
                }
           }
        }
    }
    @media screen and (max-width: 490px) {
        #section-choose-us {
           .choose-us-description {
                padding: 0 1rem;
                .choose-us-content {
                    font-size: 1.2rem;
                    line-height: 2rem;
                }
           }
           .choose-us-photos {
                width: 80%;
                height: 100%;
           }
           .choose-us-h1 {
                font-size: 1.2rem;
                line-height: 2rem !important;
           }
           .choose-us-feature {
                flex-direction: column;
                width: 100%;
                align-items: center;
                .feature-card {
                    width: 100%;
                    margin-bottom: 2rem;
                    h1 {
                        font-size: 1.5rem !important;
                    }
                    h4 {
                        font-size: 1.2rem;
                    }
                }
           }
        }
    }

    #section-about {
        //height: 90vh;
        min-height: max-content;
        background-image: url('../../public/images/bg-2.jpg');
        background-size: cover;
        background-position: center;
        //padding-bottom: 3rem;
        .section-sub {
            min-height: max-content;
            padding-bottom: 2rem;
        }

    }

    .about-container {
        margin-top: 3rem;
        width: 100%;
        height: 100%;
        display: flex;
    }
    .about-photos {
        width: 60%;
        position: relative;

        .about-photo-1, .about-photo-2, .about-photo-3 {
            position: absolute;
            height: 15rem;
            transition: all ease-in-out .8s;
            z-index: 0;
            &:hover {
                z-index: 10;
                transform: scale(1.2);
                box-shadow: 0 0 15px grey;
            }
        }
        .about-photo-1 {
            top: 50%;
            right: 40%;
        }
        .about-photo-2 {
            top: 30%;
            left: 50%;
        }
        .about-photo-3 {
            top: 20%;
            left: 10%;
        }
        
    }
    .about-description {
        width: 40%;
        padding-right: 3rem;
        font-size: 1.1rem;
        display: flex;
        line-height: 2rem;
        align-items: center;
        font-family: "Poppins", Sans-serif;
        color: #726f6f;
    }
    #about-company-logo {
        height: 70%;
    }
    @media screen and (max-width: 1800px) {
        #section-about {
            .section-sub {
                width: 80%;
                padding-bottom: 3rem;
            }
        }
    }
    @media screen and (max-width: 1600px) {
        #section-about {
            .section-sub {
                width: 100%;
            }
        }
    }
    @media screen and (max-width: 1250px) {
        #section-about {
            .about-photo-1 {
                left: 20% !important;
            }
            #about-company-logo {
                height: 10rem !important;
                display: block;
            }
        }
    }
    @media screen and (max-width: 1120px) {
        #section-about {
            min-height: max-content;
            .about-container {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
                margin-top: 0;
            }
            .about-description {
                width: 75%;
                margin-top: 10rem;
                padding-right: 0;
            }
            .about-photos {
                width: 70%;
                height: 100%;
                min-height: 20rem;
                margin: 1rem auto;
                display: flex;
            }
            .about-photo-1 {
                left: 20% !important;
                top: 70%;
            }
            .about-photo-2 {
                top: 40%;
            }

            #about-company-logo {
                float: left;
                margin-right: 2rem;
                height: 7rem !important;
            }
        }
    } // ok
    @media screen and (max-width: 1051px) {
        #section-about {
            .about-content {
                font-size: 1.3rem;
            }
            .about-photos {
                width: 75%;
                margin: 1rem auto 3rem;
            }
            .about-photo-1, .about-photo-2, .about-photo-3 {
                height: 18rem;
            }
        }
    } // ok
    @media screen and (max-width: 615px) {
        #section-about {
            .about-container {
                justify-content: flex-start;
            }
            .about-description {
                margin-top: 0;
            }
            .about-photos {
                width: 100%;
                margin: 3rem 0 !important;
                height: max-content;
                display: flex;
                position: relative;
                transform: none;
            }
            .about-photo-1, .about-photo-2, .about-photo-3 {
                width: 33.33%;
                position: relative;
                left: unset !important;
                right: unset;
                top: unset;
            }
        }
    } // done
    @media screen and (max-width: 520px) {
        #section-about {
            min-height: max-content;
            .about-photo-1, .about-photo-2, .about-photo-3 {
                width: 33.33%;
                position: relative;
                height: 15rem !important;
                left: unset !important;
                right: unset;
                top: unset;
            }
            .about-photos {
                min-height: unset;
            }
            .about-content {
                font-size: 1.4rem;
            }
        }
    } // done
    @media screen and (max-width: 405px) {
        #section-about {
            height: auto;
            padding-bottom: 0;

            #about-company-logo {
                float: none;
            }

            .about-content {
                font-size: 1.4rem;
            }
        }
    } // done

    #section-services {
        min-height: 95vh;
        // background-color: #eaf6f6cc;
        background-color: rgba(255, 255, 255, 0.9);
        padding-bottom: 3rem;

        .services-container {
            margin: 0 auto;
            margin-top: 1rem;
            width: 80%;
            min-height: 60%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .service-card {
                height: 23rem;
                width: 20%;
                overflow: hidden;
                margin: 3rem;
                border-radius: 1rem;
                box-shadow: 5px 5px 10px rgb(82, 80, 80);
                padding: 0;
                transition: all ease-in-out .3s;
                display: flex;
                flex-direction: column;
                &:hover {
                    transform: scale(1.1);
                }
            }
            .service-card-img {
                height: 80%;
                width: 100%;
                margin: 0;
            }
            .card-title-container {
                width: 100%;
                display: flex;
                flex-grow: 1;
                align-items: center;
                justify-content: center;

                .service-card-title {
                    text-align: center;
                    padding: .5rem 0;
                    font-size: 1.1rem;
                    color: white;
                    background-color: #E64848;
                    width: 100%;
                    margin: 0;
                }
            }
            
        }
    }
    @media screen and (max-width: 1480px) {
        #section-services {
            .services-container {
                width: 90% !important;
                justify-content: space-around;
                .service-card {
                    width: 35% !important;
                    height: 30rem;
                }
                .service-card-img {
                    height: 80%;
                }
                .card-title-container {
                    .service-card-title {
                        font-size: 1.1rem !important;
                    }
                }
            }
        }
    } // ok
    
    @media screen and (max-width: 1050px) {
        #section-services {
            .services-container {
                width: 100% !important;
                .service-card {
                    height: 35rem !important;
                }
            }
        }
    } // ok
    @media screen and (max-width: 830px) {
        #section-services {
            .services-container {
                width: 70% !important;
                .service-card {
                    min-width: 70% !important;
                    height: 40rem !important;
                }
                .service-card-img {
                    height: 85%;
                }
                .card-title-container {
                    .service-card-title {
                        font-size: 1.5rem !important;
                    }
                }
            }
        }
    } // done
    @media screen and (max-width: 630px) {
        #section-services {
            .services-container {
                width: 100% !important;
                .service-card {
                    min-width: 70% !important;
                    height: 40rem !important;
                }
                .service-card-img {
                    height: 85%;
                }
                .card-title-container {
                    .service-card-title {
                        font-size: 1.5rem !important;
                    }
                }
            }
        }
    } // done
    @media screen and (max-width: 460px) {
        #section-services {
            .services-container {
                .service-card {
                 height: 30rem !important;
                }
            }
        }
    }

    #testimonial {
        height: 90vh;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;

        .testimonial-container {
            width: 80%;
            height: 80%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            .testimonial-item {
                width: 25%;
                height: 80%;
                display: flex;
                flex-direction: column;
                background-color: white;
                padding: 1rem;
                box-shadow: .5rem .5rem 1rem grey;
                border-radius: 1rem;
                position: relative;

                &.middle {
                    justify-content: flex-end;
                    h5 {
                        margin-bottom: 2rem;
                    }
                }

                .testimonial-content {
                    font-size: 1.2rem;
                    line-height: 2rem;
                    font-weight: 400;
                    letter-spacing: 2px;
                    color: #726f6f;
                }

                h3 {
                    margin: 1rem 0 5px;
                }
                h5 {
                    margin: 0;
                    color: #413F42;
                }
                h3, h5 {
                    text-align: center;
                }
            }

            .quote-mark {
                color: #FFCB42;

                &.right {
                    float: right;
                }
            }

            .star-container {
                display: flex;
                justify-content: center;
                align-items: center;

                .review-star {
                    color: gold;
                    margin: 3px;
                    font-size: 1.4rem;
                }
            }
            
            #review-source {
                text-decoration: none;
                color: rgba(211, 211, 211, 0.822);
                font-size: .8rem;
                position: absolute;
                bottom: 1rem;
                left: 50%;
                width: 100%;
                text-align: center;
                transform: translateX(-50%);
                &:active, &:focus {
                    text-decoration: none;
                }
            }
        }
    }
}
@media screen and (max-width: 1730px) {
    #testimonial {
        .testimonial-container {
            width: 90% !important;
            .testimonial-item {
                width: 28% !important;
                padding: .5rem !important;
                .testimonial-content {
                    font-size: 1rem !important;
                }
            }
        }
    }
}
@media screen and (max-width: 1228px) {
    #testimonial {
        .testimonial-container {
            .testimonial-item {
                .testimonial-content {
                    line-height: 1.5rem !important;
                    font-size: .9rem !important;
                }
            }
        }
    }
} // ok
@media screen and (max-width: 1052px) {
    #testimonial {
        display: flex;
        justify-content: center;
        align-items: center;
        height: max-content !important;
        padding: 5rem 0;
        .testimonial-container {
            height: max-content !important;
            flex-direction: column;
            justify-content: center !important;
            align-items: center;
            .testimonial-item {
                margin: 0 auto 4rem;
                width: 60% !important;
                min-height: 20rem !important;
                .testimonial-content {
                    line-height: 1.5rem !important;
                    font-size: 1.1rem !important;
                }
                &:last-child {
                    margin-bottom: 0 !important;
                }
            }
        }
    }
} // ok
@media screen and (max-width: 768px) {
    #testimonial {
        .testimonial-container {
            .testimonial-item {
                min-height: 23rem !important;
                .testimonial-content {
                    font-size: 1.3rem !important;
                }
            }
        }
    }
} // ok
@media screen and (max-width: 505px) {
    #testimonial {
        .testimonial-container {
            padding: 5rem 0;
            .testimonial-item {
                min-height: 30rem !important;
                width: 80% !important;
                .testimonial-content {
                    font-size: 1.3rem !important;
                    margin-right: 2rem;
                    margin-left: 2rem;
                }
            }
        }
    }
} // done
@keyframes moveToLeft {
    0% {
        transform: translateX(-100%) ;
    }
    50% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}
.title-goup {
    animation: goUp .7s ease-in;
  }
  
  