$xlarge: 1800px;
$slarge: 1620px; //second break point
$rdlarge: 1520px; //third break point
$flarge: 1250px; //fourth
$filarge: 1060px;
$slarge: 900px;
$selarge: 850px;
$mid: 768px;
$emedium: 640px;
$nsmall: 520px;

.container {
  width: 100vw;
  overflow: hidden;
  background-image: url('../public/images/in_2.jpg');
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  position: relative;
    #scroll-top-btn {
      position: fixed;
      right: 3rem;
      bottom: 4rem;
      height: 4rem;
      width: 4rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: lightgrey;
      border: 2px solid lightgrey;
      transition: all ease-in-out .3s;
      z-index: 100;
      &:hover {
          border-color: greenyellow;
          color: greenyellow;
          background-color: transparent;
      }
  }
}
.header {
  height: 2rem;
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFCB42 !important;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  box-shadow: 0 0 1rem grey;
  .header-items {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    #logo-container {
      width: 20%;
      display: flex;
      justify-content: flex-end;
    }
    #company-logo {
      height: 3rem;
    }
    #header-text {
      color: #413F42;
      width: 30%;
      text-align: right;
    }
    .header-item-container {
      display: flex;
      width: 40%;
      justify-content: flex-end;
    }
    .header-item {
      display: flex;
      color: #413F42;
      font-weight: 600;
      margin-right: 1rem;
      align-items: center;
    }
  }
}
@media screen and (max-width: 1170px) {
  .header {
    .header-items {
      #logo-container {
        width: 15%;
        justify-content: flex-start;
      }
      #header-text {
        text-align: center;
      }
    } 
  }
}
@media screen and (max-width: 834px) {
  .header {
    .header-items {
      justify-content: space-between;
      padding: 0 1rem;
      #logo-container {
        width: 10%;
        justify-content: flex-start;
      }
      #header-text {
        text-align: center;
      }
      .header-item-container {
        width: 50%;
      }
    } 
  }
}
@media screen and (max-width: 768px) {
  .header {
    height: 3rem;
    .header-items {
      #company-logo {
        height: 3.5rem;
      }
      .header-item {
        font-size: 1.1rem;
      }
    } 
  }
}
@media screen and (max-width: 643px) {
  .header {
    .header-items {
      padding: 0 2rem;
      #header-text {
        display: none;
      }
      .header-item-container {
        width: 80%;
        justify-content: space-evenly;
      }
      .header-item {
        font-size: 1.2rem;
      }
    } 
  }
}
@media screen and (max-width: 480px) {
  .header {
    .header-items {
      .header-item-container {
        justify-content: flex-end;
      }
      .header-item {
        font-size: 1rem;
      }
    } 
  }
}
.phone-number {
  text-decoration: none;
  color: #413F42;
  &:link {
    text-decoration: none;
  }
}
.contact-btn {
  padding: .8rem 1.5rem;
  background-color: #FFCB42;
  color: #413F42;
  border: none;
  outline: none;
  width: 30%;
  font-size: .9rem;
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: 2px 2px 10px rgba(80, 78, 78, 0.836);
  cursor: pointer;
  border-radius: 5px;
  transition: all ease-in .2s;
  &:hover {
    color: white;
    transform: translateY(-10%);
  }
}

.menu {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem 10rem;
  margin: 0 auto;
  background-color: rgba(15, 26, 68, 0.616);
  height: 50vh;

  #company-name-box {
    width: 60%;
    display: flex;
    flex-direction: column;
  }
  .description {
    text-transform: uppercase;
    font-size: 1rem;
    font-family: Georgia, 'Times New Roman', Times, serif;
    letter-spacing: 2px;
    // color: #413F42;
    color: white;
    margin-bottom: 3rem;
    transition: all ease 3s;
    animation: goUp .3s ease-in;
  }


  #carousel-box {
    height: 85%;
    width: 45%;
    //background-color: #FFCB42;
    margin-left: -10rem;
    overflow: hidden;
    box-shadow: 5px 5px 15px rgb(14, 13, 13);
    .carousel-img {
      height: 100%;
      width: 100%;
      transition: all ease-in .3s;
    }
  }
}
.menu-company-name {
  width: 100%;
  //color: #0F3460;
  color: #E64848;
  text-transform: uppercase;
  font-size: 2.6rem;
  font-family: Georgia, 'Times New Roman', Times, serif;
  letter-spacing: 2px;
  transition: all ease 1s;
  animation: textAppear .7s ease-in;
  margin-bottom: 1rem;

}
.name-box-container {
  width: 100%;
  // margin-left: 8rem;
}
@media screen and (max-width: $xlarge) {
  .menu {
    padding: 6rem 6rem 3rem;
  }
  .menu-company-name  {
    font-size: 2rem;
  }
  .description {
    font-size: .8rem;
  }
}
@media screen and (max-width:1675px) {
  .menu-company-name  {
    font-size: 2rem;
  }
  .description {
    font-size: .8rem;
  }
}
@media screen and (max-width: $rdlarge) { // ok
  .menu {
    .menu-company-name  {
      font-size: 2rem;
    }
    .description {
      font-size: .8rem;
    }
  }
  
}
@media screen and (max-width: $flarge) {
  .menu {
    padding: 6rem 3rem;
  }
} // ok
@media screen and (max-width: 1140px) {
  .menu {
    justify-content: space-between;
    .menu-company-name  {
      font-size: 1.8rem;
    }
    .description {
      font-size: .8rem;
    }
  }
} // ok
@media screen and (max-width: $filarge) {
  .menu {
    .menu-company-name  {
      font-size: 1.8rem;
    }
    .description {
      font-size: .8rem;
    }
    .contact-btn {
      font-size: .8rem;
      width: 30%;
    }
  }
} // ok
@media screen and (max-width: 1050px) {
  .menu {
    min-height: 50vh;
    height: auto;
    flex-direction: column;
    padding: 6rem;
    .menu-company-name  {
      font-size: 2.5rem;
    }
    .description {
      font-size: 1.3rem;
    }
    .contact-btn {
      font-size: .8rem;
      margin-top: -3rem;
      width: 20%;
    }
    #company-name-box {
      width: 90%;
    }
    #carousel-box {
      margin-top: 4rem;
      margin-left: 0 !important;
      height: 45vh !important;
      width: 90%;
    }
    .carousel-img {
      height: 100%;
    }
  }
  
}

@media screen and (max-width: $emedium) {
  .menu {
   // here
   padding: 6rem 2rem !important;
   width: 90%;
   .menu-company-name  {
    font-size: 2.3rem;
  }
  .contact-btn {
    font-size: 1rem;  
    margin-top: -3rem;
    width: 30%;
  }
  #carousel-box {
    margin-top: 4rem;
    margin-left: 0 !important;
    height: 40vh !important;
    width: 90%;
  }
  }
} // ok
@media screen and (max-width: $mid) {
  .menu {
    width: 100%;
    padding: 6rem 0 !important;
    align-items: center;
    .menu-company-name  {
      font-size: 2.5rem;
    }
    .description {
      font-size: 1.5rem;
    }
    .contact-btn {
      font-size: 1rem;
    }
  }
} // ok
@media screen and (max-width: $nsmall) {
  .menu {
    .menu-company-name  {
      font-size: 2.3rem;
    }
    .description {
      font-size: 1.5rem;
      line-height: 1.3rem;
      margin-bottom: 3rem;
    }
    .contact-btn {
      font-size: 1rem;
      width: 30%;
    }
  }
} // ok
@media screen and (max-width: 520px) {
  .menu {
    .description {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }
} // done
@media screen and (max-width: 460px) {
  .menu {
    #carousel-box {
      height: 28vh !important;
    }
    .carousel-img {
      height: 100%;
    }
    .description {
      font-size: 1.15rem !important;
      line-height: 2rem;
    }
  }
} // done
@media screen and (max-width: 426px) {
  .menu {
    .menu-company-name  {
      font-size: 1.8rem;
    }
    .contact-btn {
      width: 40%;
    }
  }
} // done


#footer-container {
  height: 5rem;
  width: 100%;
  background-color: wheat;
  position: relative;
  
  .footer-content {
    width: 80%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .footer-item {
      margin: 0 2rem;
      position: relative;
      
      &.logo {
        margin: 0;
        display: flex;
        align-items: center;
      }
      h1 {
        font-size: 1.5rem;
      }
      
      .contact-btn {
        padding: .5rem 1.5rem;
        width: 10rem;
      }
      .copy-right {
        position: absolute;
        bottom: 0;
        margin: 0;
        font-weight: 100;
        font-size: .7rem;
      }
    }
  }
}
@media screen and (max-width: 1635px) {
  #footer-container {
    .footer-content {
      width: 100%;
    }
  }
} // ok
@media screen and (max-width: 1315px) {
  #footer-container {
    .footer-content {
      .footer-item {
        margin: 0 1rem;
        position: relative;
        &.logo {
          margin: 0 2rem;
        }
        h1 {
          font-size: 1.3rem;
        }
        
        .contact-btn {
          padding: .5rem 1.5rem;
          width: 10rem;
        }
        .copy-right {
          position: absolute;
          bottom: 0;
          margin: 0;
          font-weight: 100;
          font-size: .7rem;
        }
      }
    }
  }
} // ok
@media screen and (max-width: 1202px) {
  #footer-container {
    .footer-content {
      .footer-item {
        margin: 0 1rem;
        position: relative;
        font-size: 1rem !important;
        span {
          font-size: .8rem;
        }
        h1 {
          font-size: 1.1rem;
        }
        
        .contact-btn {
          padding: .5rem 1rem;
          width: 7rem;
          font-size: .7rem;
        }
        .copy-right {
          position: absolute;
          bottom: 0;
          margin: 0;
          font-weight: 100;
          font-size: .7rem;
        }
      }
    }
  }
} // ok
@media screen and (max-width: 828px) {
  #footer-container {
    .footer-content {
      .footer-item {
        &.address {
          display: none;
        }
      }
    }
  }
} // ok
@media screen and (max-width: 610px) {
  #footer-container {
    .footer-content {
      .footer-item {
        &.email {
          display: none;
        }
      }
    }
  }
} // ok
@media screen and (max-width: 437px) {
  #footer-container {
    .footer-content {
      .footer-item {
        margin: 0 .5rem;
        h1 {
          font-size: 1rem;
        }
        &.logo {
          margin-left: 0;
          img {
            height: 2.5rem;
          }

        }
      }
    }
  }
} // done
@media screen and (max-width: 420px) {
  #container-header {
    .header-item-container {
      justify-content: center;
      .header-item.phone {
        .phone-number {
          font-size: 1.5rem;
        }
      }
      .header-item.email {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 280px) {
  #container-header {
    .header-item-container {
      justify-content: flex-end;
    }
  }
} // done

// Left to up right
@keyframes textAppear {
  0% {
    opacity: 0;
    transform: translate(-10%, 25%);
  }
  100% {
    opacity: 1;
    transform: translate(0,0);
  }
}

// Bottom to top
@keyframes goUp {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}