#projects {
    width: 100%;
    min-height: 70vh;
    background-color: rgba(255, 255, 255, 0.801);
    padding-bottom: 7rem;
    #hinh-anh-container {
        width: 80%;
        height: 100%;
        margin: 0 auto;
    }
    #project-photos {
        display: flex;
        width: 100%;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 3rem;
    }
    .project-photo {
        height: 20rem;
        width: 23%;
        margin: 0 5px 2rem;
        box-shadow: 2px 2px 5px black;
        // transition: all ease-in-out 100;
        // animation: photoGallery linear infinite 30s;
    }
}
@media screen and (max-width: 1200px) {
    #projects {
        #hinh-anh-container {
            width: 95%;
        }
    }
} // ok
@media screen and (max-width: 720px) {
    #projects {
        #hinh-anh-container {
            width: 100%;
        }
        #project-photos {
            margin-top: 5rem;
            justify-content: space-evenly;
        }
        .project-photo {
            width: 40%;
            height: 10%;
            min-height: 33rem;
            margin: 0 0 5rem;
        }
    }
} // ok
@media screen and (max-width: 550px) {
    #projects {
        .project-photo {
            width: 70%;
        }
    }
} // done
@keyframes photoGallery {
    0% {
        transform: translateX(80vw);
    }
    100% {
        transform: translateX(-80vw);
    }
}
