#section-contact {
    width: 100%;
    min-height: 70vh;
    height: max-content;
    display: flex;
    align-items: center;
    padding: 2rem 0;
    background-color: transparent;
    font-family: "Poppins", Sans-serif;

    #contact-form-container {
        width: 50%;
        height: 80%;
        margin: 4rem auto;
        padding: 2rem;
        // background-color: #F7A76C;
        background-color: #7895B2;
        color: white;
        box-shadow: 5px 5px 15px black;
        border-radius: 10px;

        .hotline-btn {
            padding: .5rem 1rem;
            width: auto;
            background-color: #E64848 !important;
            color: white;
            text-transform: capitalize;
        }
    }

    .contact-form {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        margin-top: 3rem;

        .contact-details {
            width: 45%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            height: 100%;

            .input-group {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-bottom: 1rem;
                
                span {
                    color: red;
                }

                input {
                    padding: .5rem;
                    margin: .5rem 0;
                    outline: none;
                    border-radius: 3px;
                    border: none;
                    transition: all ease-in .3s;
                    &:focus {
                        box-shadow: 0 0 10px cyan;
                    }
                }
            }
        }
        .contact-message {
            width: 45%;
            height: 100%;

            #contact-textarea {
                width: 90%;
                padding: 1rem;
                border: none;
                outline: none;
                transition: all ease-in .3s;
                &:focus {
                    box-shadow: 0 0 5px cyan;
                }
            }
        }
        
    }
    .form-action {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
    }
}
@media screen and (max-width: 1387px) {
    #section-contact {
        #contact-form-container {
            width: 70%;
        }
    }
} // ok
@media screen and (max-width: 829px) {
    #section-contact {
        display: flex;
        justify-content: center;
        align-items: center;
        #contact-form-container {
            width: 85%;
            height: 70%;
        }
    }
} // ok
@media screen and (max-width: 438px) {
    #section-contact {
        min-height: 80vh;
        height: max-content;
        #contact-form-container {
            .contact-form {
                flex-direction: column;
                align-items: center;

                .contact-details {
                    width: 95%;
                }
                .contact-message {
                    margin-top: 1rem;
                    width: 95%;
                    display: flex;
                    align-items: flex-start;
                    #contact-textarea {
                        width: 100%;
                        height: 10rem !important;
                        overflow-y: auto;
                    }
                }
            }
            .form-action {
                .contact-btn {
                    width: 40%;
                }
            }
        }
    }
} // done
